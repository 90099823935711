<template>
  <div>
    <el-alert
      title="什么是AK/SK"
      type="info"
      description="通过API网关向KubeStar服务发送请求时，必须使用AK(Access Key ID)、SK(Secret Access Key)对请求进行签名。"
      show-icon
      style="margin-bottom: 10px;"
    >
    </el-alert>

    <div class="name-value">
      <span class="name">accessKey:</span>
      <span class="value">
        <i class="el-icon-document-copy" v-clipboard:copy="form.accessKey"></i>
        {{ form.accessKey || "-" }}
      </span>
    </div>

    <div class="name-value">
      <span class="name">secretKey:</span>
      <span class="value">
        <i class="el-icon-document-copy" v-clipboard:copy="form.secretKey"></i>
        {{ form.secretKey || "-" }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    form: Object
  }
};
</script>

<style></style>
